<template>
  <section class="content">
    <fund-nav />
    <div class="logo_div">
      <div :class="['banner',{'banner-bg':showBannerBg}]">
        
          <el-carousel height="320px" v-if="banners.length>0">
            <el-carousel-item v-for="item in banners" :key="item.id">
              <a :href="item.href" :title="item.title">
                <img :src="item.src" :alt="item.title">
              </a>
            </el-carousel-item>
          </el-carousel>    
        
      </div>
      <div class="title_div" v-if="false">
        <div class="title_content">
          <div class="content_width">
            <div>
              <p class="title_eng">AUTOMATIC INVESTMENT PLAN</p>
              <p class="title_chn">基金定投</p>
              <p class="title_rule">定投是定期定额投资的简称，类似银行零存整取，在固定时间(如每月18日)以固定金(如500元)投资。 了解计算规则>></p>
            </div>
            <div align="right">
              <div style="display: inline-flex">
                <div class="justify-center pensionDiv">
                  <p class="pensionFont">养老规划 →</p>
                </div>
                <div class="justify-center">
                  <p class="pFont">长线产品</p>
                </div>
                <div class="justify-center">
                  <p class="pFont">理财规划</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="banner_fixed">
      <div class="content_width banner_fixed_content">
        <span class="icon ico-calc"></span>
        <router-link class="btn" :to="{name:'fixedCal'}">定投计算器</router-link>
        <span class="line">|</span>
        <span class="icon iconfont icon-a-fundmarket-points"></span>
        <router-link :to="{name:'FundSetFing'}" class="btn">我的定投</router-link>
      </div>
    </div>
    <div class="recommend_div flex-between vw" v-if="themeList.length > 0">
      <div class="recommend_content_left">
        <div :class="['justify-center left_box btn',{'current':themeIndex===index}]" v-for="(item,index) in themeList" :key="index" @click="changeTheme(item.themeId,index)">
          <div class="ico">
            <span :class="['ico-direction','ico-direction-'+(index+1)]"></span>
          </div>
          <div class="txt">
            <p class="title">{{ item.themeName }}</p>
            <p class="summary gray">{{ item.themeDescribe }}</p>
          </div>
        </div>
      </div>
      <div class="recommend_content_right">
        <div class="recommend_content_right_title">
          <img :src="themeData.themeLogo">
        </div>
        <div class="recommend_content_right_content flex-between">
          <router-link class="fund_box"  v-for="(item,index) in themeFundList[themeFundIndex]" :key="index"  :to="{name:'FundDetail',params:{fundCode: item.fundCode,fundType:item.ofundType}}">
            <p class="gray small">基金代码 {{ item.fundCode }}</p>
            <p class="title">
              <a>{{ item.fundName }}</a>
            </p>
            <p class="gray small">{{ item.fundManagerName }}</p>
            <p class="risk-box">
              <span class="risk" :class="item.ofundRisklevel > 3 ? 'risk-h' : item.ofundRisklevel > 1 ? 'risk-m' : ''" v-if="item.riskLevel">{{ item.riskLevel }}</span>
              <span class="type">{{ item.fundType }}</span>
            </p>
            <p class="date gray">{{ item.enddate }}</p>
            <p class="value-box">
              <span class="gray">最新净值: </span>
              <span class="value"> {{ item.newestValue }}</span>
            </p>
            <p class="rate-box">
              <span class="gray">近一年收益:</span>
              <br>
              <span class="rate">{{ item.incomeYear }}</span>
              <small>%</small>
            </p>
            <p class="buy-box">
              <a :class="['btn-buy btn-orange-plain', {'disable stop-buy':item.buyStatus === '0'}]" @click.prevent="buyFund(item)">一键定投</a>
            </p>
          </router-link>        
        </div>
        <div class="flex-between navmore" v-if="themeFundList.length > 1">
          <span class="pre ico-pre btn" @click="preThemeFunds"></span>
          <div class="selector">
            <span v-for="index in themeFundList.length" :key="index" :class="index-1 === themeFundIndex ? 'ico-spot': 'ico-circle'"></span>
          </div>
          <span class="next ico-next btn" @click="nextThemeFunds"></span>
        </div>
      </div>
    </div>
    <div class="fund-order vw">
      <div :class="['flex-between fund-cat-sec']">
        <div :class="['fund-cat-all',{'current': !fundOrderCat}]" @click="getFundSort()">
          <p>全部基金</p>
        </div>
        <div class="fund-cat-box flex-between">
          <div :class="{'current': fundOrderCat===item.code}" v-for="item in fundOrderCats" :key="item.code" @click="getFundSort(item.code)">
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
      <div class="flex-between fund-order-title fund-order-list">
        <span>基金代码</span>
        <span class="name">基金名称</span>
        <span>风险等级</span>
        <span class="flex-between">
          <span>最新净值</span>
          <i :class="['btn', fundSortStyle('newestValue')]" @click="sortFund('newestValue')"></i>
        </span>
        <span class="flex-between">
          <span>日涨跌幅</span>
          <i :class="['btn', fundSortStyle('changeDay')]" @click="sortFund('changeDay')"></i>
        </span>
        <span class="flex-between">
          <span>
            近一个月<br>涨跌幅 
          </span>
          <i :class="['btn', fundSortStyle('changeMonth')]" @click="sortFund('changeMonth')"></i>
        </span>
        <span class="flex-between">
          <span>
            近三个月<br>涨跌幅 
          </span>
          <i :class="['btn', fundSortStyle('changeThreeMonth')]" @click="sortFund('changeThreeMonth')"></i>
        </span>
        <span class="flex-between">
          <span>
            近半年<br>涨跌幅 
          </span>
          <i :class="['btn', fundSortStyle('changeHalfYear')]" @click="sortFund('changeHalfYear')"></i>
        </span>
        <span class="flex-between">
          <span>
            近一年<br>涨跌幅 
          </span>
          <i :class="['btn', fundSortStyle('changeYear')]" @click="sortFund('changeYear')"></i>
        </span>
        <span v-if="false">买入费率</span>
        <span class="action">操作</span>
      </div>
      <div class="flex-between dark fund-order-content fund-order-list" v-for="item in fundOrderData" :key="item.fundCode">
        <span class="">{{ item.fundCode }}</span>
        <router-link class="name" :to="{name:'FundDetail',params:{fundCode: item.fundCode,fundType:item.ofundType}}">{{ item.fundName }}</router-link>
        <span :class="item.ofundRisklevel > 3 ? 'red' : item.ofundRisklevel > 1 ? 'orange' : ''">{{ item.risklevel }}</span>
        <span class="value dark">
          <span>{{ item.newestValue }}</span>
          <br>
          <span class="gray">{{ item.enddate }}</span>
        </span>
        <span :class="formatRiseStyle(item.changeDay)">{{ item.changeDay }}%</span>
        <span :class="formatRiseStyle(item.changeMonth)">{{ item.changeMonth }}%</span>
        <span :class="formatRiseStyle(item.changeThreeMonth)">{{ item.changeThreeMonth }}%</span>
        <span :class="formatRiseStyle(item.changeHalfYear)">{{ item.changeHalfYear }}%</span>
        <span :class="formatRiseStyle(item.changeYear)">{{ item.changeYear }}%</span>
        <span :class="item.saleFeeRate.indexOf('%') > -1 ? 'orange' : 'gray'" v-if="false">{{ item.saleFeeRate}}</span>
        <span class="action flex-between">
          <input type="button" value="一键定投" :class="['btn btn-orange-plain btn-buy', {'disable stop-buy':item.buyStatus === '0'}]" @click="buyFund(item)">
        </span>
      </div>
      <div class="page-bar vw">
        <page-bar :total="pageTotal" :display="5" @click="goPage" />
      </div>
    </div>    
    <DinDetails :showmodel="showBuyConfirm" @cancels="showBuyConfirm=false" @showdelte="showBuyConfirm=false" @confirms="buyConfirm">
      {{buyConfirmTxt}}
    </DinDetails>
  </section>
</template>

<script>
import FundNav from '../components/FundNav.vue'
import PageBar from '@/components/Pagebar.vue'
import DinDetails from '@/components/Models.vue'
import {fundList,themeList} from '@/api/fund.js'
import {getDicts} from '@/api/dict.js'
import {getImgs} from '@/api/other/index.js'

export default {
  name: "FixedArea",
  components:{
    FundNav,PageBar,DinDetails
  },
  data() {
    return {
      banners:[],
      showBannerBg:false,
      
      themeIndex: 0,
      themeList: [], // 主题列表
      themeData: {}, // 当前主题信息
      themeFundList: [], // 主题基金列表
      themeFundIndex: 0, //主题基金块索引
      
      fundOrderCats: [],
      fundOrderCat: '',
      fundOrderType: {},
      fundOrderData: [],
      riskLevels: [],
      
      pageTotal: 0,
      pageNum: 1,
      
      showBuyConfirm: false,
      buyConfirmTxt: '',
      buyConfirmStep: 0,
      preBuyFund:null
    }
  },
  created() {
    this.getBanner()
    this.getFundType().then(() => {
      this.getRiskLevel().then(() => {
        this.getThemeList()
        this.sortFund('changeDay')
      })
    })
  },
  methods: {
    /** 获取banner */
    getBanner() {
      getDicts('sys_img_user_site').then(result => {
        let imgUseSite = (result.data.find(m => m.dictLabel === 'web端定投基金轮播')||{}).dictValue
        if(imgUseSite) {
          getImgs('1',imgUseSite).then(imgResult => {
            this.banners = Array.from(imgResult.data,m => {
              return {
                title:m.imgName,
                href:m.imgLinkedUrl,
                src:m.imgUrl,
                id:m.imgId
              }
            })
            this.showBannerBg = this.banners.length === 0
          }).catch(() => {
            this.showBannerBg = true
          })
        } else {
          this.showBannerBg = true
        }
      })
    },
    /** 切换主题 */
    changeTheme(themeId,index) {
      this.themeIndex = index
      this.themeData = this.themeList[index]
      this.getThemeFundList(themeId)
    },
    /** 获取主题 */
    getThemeList() {
      themeList(1,4,'2','2','1').then(res => {
        if (res.data.data && res.data.data.length > 0) {
          this.themeList = res.data.data;
          this.themeData = this.themeList[this.themeIndex];
          this.getThemeFundList(this.themeData.themeId);
        }
      })
    },
    /** 获取主题基金 */
    getThemeFundList(themeId) {
      var param = {
        pageNum: 1,
        pageSize: 9,
        allowFix: "1", // 定投
        privateFlag: "0", // 公募
        customerWebInvestTheme: themeId,
        customerWebInvestThemeTop: '1'
      };
      fundList(param).then(result => {
        if(!result.data) {
          return;
        }
        let data = result.data.data
        data.forEach(m => {
          m.riskLevel = (this.riskLevels.find(n => m.ofundRisklevel === n.code)||{}).name
          m.ofundRisklevel = parseInt(m.ofundRisklevel)
          m.fundType = (this.fundOrderCats.find(n => m.ofundType === n.code)||{}).name
          m.newestValue = m.newestValue.$trimZero()
          m.incomeYear = m.incomeYear.$trimZero(2)
        })
        let themeFunds = []
        for(let i = 0; i < 3; i++) {          
          let _data = data.slice(3*i,3*(i+1))
          if(_data.length>0){
            themeFunds.push(_data)
          }
        }
        this.themeFundList = themeFunds
        this.themeFundIndex = 0
      })
    },
    /** 上一页主题基金 */
    preThemeFunds() {
      if(this.themeFundList.length < 1) {
        return
      }
      if(this.themeFundIndex === 0) {
        this.themeFundIndex = this.themeFundList.length - 1
      }else {
        this.themeFundIndex -= 1
      }
    },
    /** 下一页主题基金 */
    nextThemeFunds() {
      if(this.themeFundList.length < 1) {
        return
      }
      if(this.themeFundIndex === this.themeFundList.length - 1) {
        this.themeFundIndex = 0
      }else {
        this.themeFundIndex += 1
      }
    },
    /** 购买基金 */
    buyFund(fund) {
      if(fund.buyStatus === '0') {
        return;
      }
      this.buyConfirmStep = 0
      let token = this.$store.getters.token;
      let _userInfo = localStorage.getItem("customerInfo");
      if (!token || !_userInfo) {
        this.buyConfirmTxt = '请登录!'
        this.buyConfirmStep = 1
      }else {
        let userInfo = JSON.parse(_userInfo);
        if (!userInfo.acctFlag || userInfo.acctFlag == "0") {
          this.buyConfirmTxt = '请先开户!'
          this.buyConfirmStep = 2
        }else if (!userInfo.addDataFlag || userInfo.addDataFlag == "0") {
          this.buyConfirmTxt = '请补充资料!'
          this.buyConfirmStep = 3
        }else if (!userInfo.tradePwdFlag || userInfo.tradePwdFlag == "0") {
          this.buyConfirmTxt = '请设置交易密码!'
          this.buyConfirmStep = 4
        }else if (!userInfo.riskFlag || userInfo.riskFlag == "0") {
          this.buyConfirmTxt = '请先完成风险测评!'
          this.buyConfirmStep = 5
        }else if(userInfo.riskLevel < fund.ofundRisklevel){
          this.buyConfirmTxt = '当前产品风险等级高于您的风险测评等级，确认购买？'
          this.buyConfirmStep = 6
        }
      }
      this.preBuyFund = fund
      if(!this.buyConfirmStep) {
        this.buyConfirm()
        return
      }      
      this.showBuyConfirm = true
    },
    /** 购买基金确认 */
    buyConfirm() {
      this.showBuyConfirm = false
      if(this.buyConfirmStep === 1) { 
        this.$router.push({ name: "Login" })
        return
      }
      if(this.buyConfirmStep === 2) { 
        this.$router.push({ name: "OpenAccount" });
        return
      }
      if(this.buyConfirmStep === 3) { 
        this.$router.push({ name: "PersonalDataSupply" });
        return
      }
      if(this.buyConfirmStep === 4) { 
        this.$router.push({ name: "OpenAccount" });
        return
      }
      if(this.buyConfirmStep === 5) { 
        this.$router.push({ name: "RiskAssessment" });
        return
      }
      this.$router.push({name:'AddProcess',params:{fundCode:this.preBuyFund.fundCode,newfund:this.preBuyFund.newfund}})
    },
    /** 获取基金类型 */
    getFundType() {
      return getDicts('sys_fund_type').then(result => {
        if(!result.data) {
          return
        }
        this.fundOrderCats = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
      })
    },
    /** 获取基金风险级别 */
    getRiskLevel() {
      return getDicts('sys_fund_risklevel').then(result => {
        if(!result.data) {
          return
        }
        this.riskLevels = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
      })
    },
    /** 基金排序箭头样式 */
    fundSortStyle(key) {
      return this.fundOrderType[key] === '-1' ? 'ico-sort-down' : this.fundOrderType[key] === '1' ? 'ico-sort-up' : 'ico-sort'
    },
    /** 基金排序 */
    sortFund(key) {
      let order = this.fundOrderType[key]
      order = !order ? '-1' : order === '-1' ? '1' : ''
      this.fundOrderType = {}
      this.fundOrderType[key] = order
      this.getFundSort(this.fundOrderCat)
    },
    /** 获取基金排行 */
    getFundSort(cat) {
      this.fundOrderCat = cat
      let params = {
        pageSize:10,
        pageNum:this.pageNum,
        allowFix: "1",
        privateFlag: '0'
      }
      params.ofundType = cat
      let _params = {...params,...this.fundOrderType}
      fundList(_params).then(result => {
        let data = result.data.data
        data.forEach(m => {
          m.risklevel = ((this.riskLevels.find(n => m.ofundRisklevel === n.code)||{}).name || '').replace('风险','')
          m.ofundRisklevel = parseInt(m.ofundRisklevel)
          m.newestValue = m.newestValue.$trimZero()
          m.changeDay = (parseFloat(m.changeDay)>0?'+':'')+m.changeDay.$trimZero(2)
          m.changeMonth = (parseFloat(m.changeMonth)>0?'+':'')+m.changeMonth.$trimZero(2)
          m.changeThreeMonth = (parseFloat(m.changeThreeMonth)>0?'+':'')+m.changeThreeMonth.$trimZero(2)
          m.changeHalfYear = (parseFloat(m.changeHalfYear)>0?'+':'')+m.changeHalfYear.$trimZero(2)
          m.changeYear = (parseFloat(m.changeYear)>0?'+':'')+m.changeYear.$trimZero(2)
          m.saleFeeRate = m.saleFeeRate ? m.saleFeeRate + '%' : '免手续费'
        })
        this.fundOrderData = data
        this.pageTotal = result.data.pages
      })
    },
    /** 格式化基金涨跌样式
     * @param {Number|String} priceRate 涨跌幅
     */
    formatRiseStyle(priceRate) {
      priceRate = priceRate || 0
      priceRate = parseFloat(priceRate)
      return priceRate > 0 ? 'red' : priceRate < 0 ? 'green' : ''
    },
    /** 页跳转 */
    goPage(page) {
      this.pageNum = page
      this.getFundSort(this.fundOrderCat)
    }
  }
}
</script>

<style lang="less" scoped>
  @orange:#CE9B63;
  @gray:#999;
  @red:#D43F3F;
  @green:#55aa00;
  .orange{color:@orange;}
  .gray{color:@gray;}
  .red{ color:@red;}
  .small{font-size: 12px;}
  .green{ color: @green;}
  .btn-orange-plain{ border:1px solid @orange; color:@orange; background: #fff; border-radius: 3px;}
.content { width: 100%; background: #f3f4f6; }
.content_width { width: 1200px; margin: 0 auto; }

.banner{ height:320px;
  img{ display: block; margin:auto;}
}
.banner-bg{ background: url(../../../static/img/banner/dingtou.jpg) no-repeat center center #111328;}
.title_div { display: flex; align-items: center; justify-content: center; }
.title_content { height: 320px; color: white;/*border: 1px solid red;*/
}
.title_eng { margin-top: 56px; font-size: 36px; font-family: B612, B612-Regular; font-weight: 400; text-align: LEFT; line-height: 36px; }
.title_chn { margin-top: 15px; font-size: 66px; font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold; font-weight: 700; text-align: LEFT; line-height: 66px; letter-spacing: 0px; }
.title_rule { margin-top: 23px; width: 720px; height: 67px; opacity: 1; font-size: 16px; font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular; font-weight: 400; text-align: LEFT; color: #ffffff; line-height: 32px; }
.justify-center { display: flex; align-items: center; justify-content: center; }
.pensionDiv { width: 129px; height: 36px; border: 1px solid #ffffff; border-radius: 19px; }
.pensionFont { width: 80px; height: 14px; font-size: 14px; font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular; font-weight: 400; text-align: CENTER; line-height: 14px; }
.pFont { margin-left: 23px; width: 56px; height: 14px; font-size: 14px; font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold; font-weight: 700; text-align: CENTER; line-height: 14px; }
.banner_fixed { height: 68px; background: #ce9b63; font:22px/68px "microsoft yahei"; color:#fff; 
  .icon{margin-right: 12px; font-size: 20px;}
  .line{padding:0 40px; display: inline-block;}
  a{ color:#fff;}
}
.recommend_div { margin-top:80px;}
.recommend_content_left { background: #ffffff url(../../../static/img/fund-market/cast-surely/left-bg.png) bottom no-repeat; width: 286px;box-shadow: 0 0 20px #ddd; margin-top:-40px;
  .left_box { width: 286px; min-height: 60px; background: rgba(255,255,255,0.8); padding:20px 0;
    .ico{ font-size:35px; color:@orange; width:35px;height: 35px; margin-right: 20px;}
    .txt{width:175px; text-align: left;
      .title{font-size:20px; margin-bottom: 10px; height:1em; line-height: 1em; overflow:hidden;}
      .summary{ max-height: 3em; overflow: hidden;}
    }
   }
  .current{background: @orange;
    *{ color:#fff;}
    .ico{ color:@orange;}
  }
}
.recommend_content_right { width: 870px; padding: 20px;
  .navmore{ padding:8px 6px; align-items: center;}
  .pre,.next{ font:bold 30px "microsoft yahei"; padding:8px; color:#ddd; border:1px solid #eee;}
  .selector{.orange(); font-size:20px;}
  .ico-spot{box-shadow: 0 0 5px; border-radius: 1em; margin:0 5px;}
}
.recommend_content_right_title {
  img{ width: 100%; height:300px; border-radius: 1em;}
}
.recommend_content_right_content {  margin-top:40px; justify-content: flex-start;
  .fund_box {border: 1px solid #edf1f7; padding:12px 20px; width:27.5%; margin:0 6px;
    .title{font:bold 24px "microsoft yahei"; line-height: 2em; height:2em; overflow: hidden;}
    .risk-box{ padding:20px 0; display: flex;
      *{padding:3px 12px; border-radius: 3px;}
      .risk{  margin-right:8px;}
      .risk-m{color:@orange; border:1px solid @orange;}
      .risk-h{color:#f00; border:1px solid #f00;}
      .type{ color:@gray; border:1px solid @gray;}
    }
    .value-box{font-size:18px; line-height: 2em;
      .value{font-size: 1.4em;}
    }
    .rate-box{ font-size:16px; color:@red; margin-top:5px;
      .rate{font:bold 40px "microsoft yahei"; line-height: 1.6em;}
      small{font-size: 24px; margin-left: 2px;}
    }
    .buy-box{ padding:30px 0;
      .btn-buy{ width:100%; height:35px; line-height: 35px; text-align: center; display: block; font-size: 16px;}
    }
  }
  .fund_box:hover{
    border-color: #fff; box-shadow: 0 0 15px #eee;
    .btn-buy {color:@orange; background: #F2D0AB; padding:1px 0; border:none;}
  }
}

.fund-order{ padding: 20px 0; margin-top:40px;
	.fund-cat-box{ width: 1080px; flex-wrap: wrap; justify-content: flex-start;}
	.fund-cat-all,
	.fund-cat-box>div{color: #666; padding: 10px; text-align: center; position: relative; height: 1.5em; cursor: pointer; user-select: none; border-radius: 3px;
  }
	.fund-cat-box>div { margin: 0em 1.5em 0.5em 0; }
	.show-less{ height:3em;}
	.fund-cat-sec {overflow: hidden; font-size: 18px;
		.ico-sort-down{ position: absolute; bottom: -7px; left:45%; display: none;}
		div.current{ border-color: #deb87b; background: #deb87b; color: #fff;}
		.current .ico-sort-down{ display: block;}
	}
	.fund-order-list{ text-align: center;}
	.fund-order-title{ background: #F9F9FA; padding: 0.5em 0; margin-top: 1.5em; align-items: center; font-size:15px;}
	.fund-order-title .btn{ font-size: 1.2em; margin: 0px 0 0 2px;}
	.fund-order-title>span{ justify-content: center; align-items: center;}
	.fund-order-list>span{ width: 7em;}
	.fund-order-list .name{ text-align: left; width:200px;}
	.fund-order-list .action {width:170px;}
	.fund-order-content { padding: 1.8em 0;  line-height: 2em; border-bottom: 1px solid #eee;
		.value{ line-height: 1.5em;}
		.dark{ color: #5d5d5d;}
		.btn-buy{ padding: 10px 50px; height:2.5em;
      &:hover{color:#fff; background: @orange; border-color: @orange;}
    }
	}
  .page-bar{ text-align: right; background: #fff; padding:1em 0;}
}
</style>