<!-- 
	author:张洪志
	description:基金导航 
-->
<template>
	<section class="fund-nav">
		<div class="vw">
			<router-link :to="{name:'FundMarket'}" active-class="current">全部基金</router-link>
			<router-link :to="{name:'TopicFund'}" active-class="current">主题基金</router-link>
			<router-link :to="{name:'NewFund'}" active-class="current">新发基金</router-link>
			<router-link :to="{name:'Fixedarea'}" active-class="current">定投专区</router-link>
		</div>
	</section>
</template>

<script>
export default {
	name: 'FundNav'
}
</script>

<style lang="less" scoped>	
	.fund-nav{ height: 36px; line-height: 36px; font-size: 1.1em; background: #fff;
		a{ margin-right: 3em;}
		.current{ color:#CE9B63; font-weight: bold;}
	}
</style>
