<template>
  <section class="page-bar">
    <a @click="goPre()" v-if="showPre">&lt;</a>
    <a :class="{'current':first === current}" @click="goPage(first)" v-if="showFirst">1</a>
    <span @click="goUp()" v-if="showPre && start > 2">…</span>
    <a :class="{'current':page === current}" @click="goPage(page)" v-for="page in pages" :key="page">{{page}}</a>
    <span @click="goDown()" v-if="showNext && end < last - 1">…</span>
    <a :class="{'current':last === current}" @click="goPage(last)" v-if="showLast">{{last}}</a>
    <a @click="goNext()" v-if="showNext">&gt;</a>
  </section>
</template>

<script>
  export default {
    name: 'PageBar',
    props: {
      total: Number,
      display: Number
    },
    data() {
      return {
        first: 1,
        current: 1
      }
    },
    computed: {
      last() {
        return this.total
      },
      start() {
        let value = this.current - parseInt(this.display / 2)
        let fix = value + this.display - this.last
        if(fix > 0) {
          value -= fix
        }
        return value < this.first ? this.first : value
      },
      end() {
        let value = this.start + this.display - 1
        return value > this.last ? this.last : value
      },
      showPre() {
        return this.current > this.first
      },
      showNext() {
        return this.current < this.last
      },
      showUp() {
        return this.start > this.first + 1
      },
      showDown() {
        return this.end < this.last - 1
      },
      showFirst() {
        return this.start >= this.first + 1
      },
      showLast() {
        return this.end <= this.last - 1
      },
      pages() {
        let values = []
        for (let page = this.start; page <= this.end; page++) {
          values.push(page)
        }
        return values
      }
    },
    methods: {
      goPage(page) {
        this.current = page
        this.$emit('click',page)
      },
      goPre() {
        let page = this.current - 1
        this.goPage(page)
      },
      goNext() {
        let page = this.current + 1
        this.goPage(page)
      },
      goUp() {
        let page = this.current - this.display
        page = page < this.first ? this.first : page
        this.goPage(page)
      },
      goDown() {
        let page = this.current + this.display
        page = page > this.last ? this.last : page
        this.goPage(page)
      }
    }
  }
</script>

<style lang="less" scoped>
  @orange:#c78d19;
  @gray:#ddd;
  .page-bar>*{ cursor: pointer; user-select: none;}
  a{padding:0.4em 0.5em; margin: 0 0.5em; border: 1px solid @gray; border-radius: 3px; min-width: 1.2em; text-align: center; display: inline-block;}
  a.current{ color: @orange; border: 1px solid @orange;}
  span{ color:@gray;}
</style>
